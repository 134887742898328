<template>
  <div>
    <div class="header">
      <a class="logo" href="/">
        <img class="logo1" src="@/assets/icons/logo.svg" />
        <img
          class="logo2"
          src="@/assets/icons/100swap.svg"
        />
      </a>
      <div class="navs">
        <a
          class="homeBtn"
          href="/"
          :class="{ active: getRoutePath === '/' }"
          >Home</a
        >
        <a href="/swap" :class="{ active: getRoutePath === '/swap' }">Swap</a>
        <a href="/pools" :class="{ active: getRoutePath === '/pools' }"
          >Pools</a
        >
        <!-- <a href="/farming" :class="{ active: getRoutePath === '/farming' }"
          >Farming</a
        > -->
        <a class="layerBtn" :href="base100LayerUrl" target="_blank"
          >WBTC</a
        >
        <!-- <a href="/swap" :class="{ active: getRoutePath === '/swap' }">Swap</a> -->
        <a class="brc100Btn" href="https://brc100.org/" target="_blank" v-if="!appInfo.isMobile"
          >BRC-100</a
        >
        <a
          class="inbrcBtn"
          v-if="!appInfo.isMobile"
          :href="`${baseInbrcUrl}token?ticker=bos`"
          target="_blank"
          >inBRC</a
        >
        <a
          class="documentionBtn"
          v-if="!appInfo.isMobile"
          href="https://docs.100swap.io/"
          target="_blank"
          >Documention</a
        >
      </div>
      <a
        class="bosPrice"
        :href="`/token?ticker=bos`"
        target="_blank"
        >bos: {{ tickerPrice && "$" }}{{ tickerPrice | noDataFilter | moneyFilter(4) }}</a
      >
      <div class="connect" v-show="!hasConnected" @click="showConnectDialog">
        Connect
      </div>
      <div
        class="connect"
        @click="toggleDisconnectDropdown"
        v-show="hasConnected"
      >
        {{ userAddress | addressFilter(6, 4) }}
        <div class="address-dropdown" v-show="dropdownVisible">
          <div class="address">
            <i class="el-icon-user" />
            <a :href="addressUrl" target="_blank">{{
              userAddress | addressFilter(8, 6)
            }}</a>
            <img
              @click="copyAction(userAddress)"
              src="@/assets/home/copy@2x.png"
              alt=""
            />
          </div>
          <div class="line"></div>
          <div @click.prevent="handleClickDisconnect" class="disconnect">
            <img src="@/assets/home/logout@2x.png" alt="" />
            <span>Disconnect</span>
          </div>
        </div>
      </div>
      <div class="test" v-if="isTestnet">Testnet</div>
      <div class="test" v-if="!isTestnet">Mainnet</div>
    </div>
    <ConnectWallet @select-wallet="handleConnect" ref="ConnectWalletRef" />
  </div>
</template>

<script>
import { addressFilter } from "@/common/filters";
import wallet, { WALLET_ENUM } from "@/common/wallet";
import { mapState } from "vuex";
import LocalStorageManager from "@/common/storage";
import { baseInbrcUrl, isProduction, base100SwapUrl, base100LayerUrl } from "@/common/config";
import ConnectWallet from "./ConnectWallet.vue";
import { validate, getAddressInfo } from "bitcoin-address-validation";
import { Notification } from "element-ui";
import { getHomeData } from '@/api/home';


export default {
  name: "CommonHeader",
  data() {
    return {
      hasConnected: false,
      timer: null,
      dropdownVisible: false,
      isTestnet: !isProduction,
      floorPrice: '',
      baseInbrcUrl,
      base100SwapUrl,
      base100LayerUrl,
      tickerPrice: ""
    };
  },
  components: {
    ConnectWallet,
  },
  inject: ["appInfo"],
  mounted() {
    this.init();

    getHomeData({
      ticker: 'bos',
    }).then((res) => {
      if(res?.data && res.data?.floorPrice) {
        this.floorPrice = res.data?.floorPrice;
        this.tickerPrice = res.data?.priceInSwapUSD;
      }
    });
  },
  beforeDestroy() {
    this.timer = null;
  },
  computed: {
    ...mapState(["userAddress", "btcPrice"]),
    getRoutePath() {
      return this.$route.path;
    },
    addressUrl() {
      return `${baseInbrcUrl}address?address=${this.userAddress}`;
    },
    $bosPriceVal() {
      if(!['', null, undefined].includes(this.floorPrice)) {
        return '$' + (
          (Number(this.floorPrice) * Number(this.btcPrice)) / 100000000
        ).toFixed(4);
      }
      return '--'
    }
  },
  methods: {
    async init() {
      this.timer = setInterval(async () => {
        if (
          (wallet.hasConnectedWallet() === WALLET_ENUM.UNISAT_WALLET &&
            window.unisat) ||
          (wallet.hasConnectedWallet() === WALLET_ENUM.OKX_WALLET &&
            window.okxwallet) ||
            wallet.hasConnectedWallet() === WALLET_ENUM.XVERSE_WALLET
        ) {
          clearInterval(this.timer);
          let [address, publicKey] = await wallet.connect();
          let addressInfo = getAddressInfo(address);
          if (addressInfo.network === "testnet" && isProduction) {
            Notification({
              message: "Please switch to mainnet!",
              type: "warning",
            });
            if (wallet.hasConnectedWallet() === WALLET_ENUM.UNISAT_WALLET) {
              await window.unisat.switchNetwork("livenet");
              location.reload();
            } else {
              return;
            }
          }
          if (addressInfo.network === "mainnet" && !isProduction) {
            Notification({
              message: "Please switch to testnet!",
              type: "warning",
            });
            if (wallet.hasConnectedWallet() === WALLET_ENUM.UNISAT_WALLET) {
              await window.unisat.switchNetwork("testnet");
              location.reload();
            } else {
              return;
            }
          }
          if (address) {
            this.$store.commit("SET_USER_ADDRESS", address);
            this.$store.commit("SET_USER_PUBLIC_KEY", publicKey);
            LocalStorageManager.setItem("100SWAP_CONNECTED_ADDRESS", address);
            this.hasConnected = true;
          }
          wallet.accountsChanged();
        } else {
          clearInterval(this.timer);
        }
      }, 500);
    },
    showConnectDialog() {
      this.$refs.ConnectWalletRef.open();
    },
    async handleConnect(type) {
      try {
        let [address, publicKey] = await wallet.connect(type);
        let addressInfo = getAddressInfo(address);
        if (addressInfo.network === "testnet" && isProduction) {
          Notification({
            message: "Please switch to mainnet!",
            type: "warning",
          });
          if (type === WALLET_ENUM.UNISAT_WALLET) {
            await window.unisat.switchNetwork("livenet");
            location.reload();
          } else {
            return;
          }
        }
        if (addressInfo.network === "mainnet" && !isProduction) {
          Notification({
            message: "Please switch to testnet!",
            type: "warning",
          });
          if (type === WALLET_ENUM.UNISAT_WALLET) {
            await window.unisat.switchNetwork("testnet");
            location.reload();
          } else {
            return;
          }
        }
        if (address) {
          this.$store.commit("SET_USER_ADDRESS", address);
          this.$store.commit("SET_USER_PUBLIC_KEY", publicKey);
          LocalStorageManager.setItem("100SWAP_CONNECT_WALLET", type);
          LocalStorageManager.setItem("100SWAP_CONNECTED_ADDRESS", address);
          if (wallet.hasConnectedWallet() === WALLET_ENUM.XVERSE_WALLET) {
            LocalStorageManager.setItem("100SWAP_XVERSE_USER_ADDRESS", address);
            LocalStorageManager.setItem(
              "100SWAP_XVERSE_USER_PUBLIC_KEY",
              publicKey
            );
          }
          this.hasConnected = true;
          this.$refs.ConnectWalletRef.close();
          this.dropdownVisible = false;
        }
      } catch (error) {
        this.$refs.ConnectWalletRef.refuseConnect();
      }
    },
    toggleDisconnectDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    closeDisconnectDropdown() {
      this.dropdownVisible = false;
    },
    handleClickDisconnect() {
      this.$store.commit("SET_USER_ADDRESS", "");
      this.$store.commit("SET_USER_PUBLIC_KEY", "");
      LocalStorageManager.removeItem("100SWAP_CONNECT_WALLET");
      LocalStorageManager.removeItem("100SWAP_CONNECTED_ADDRESS");
      if (wallet.hasConnectedWallet() === WALLET_ENUM.XVERSE_WALLET) {
        LocalStorageManager.removeItem("100SWAP_XVERSE_USER_ADDRESS");
        LocalStorageManager.removeItem("100SWAP_XVERSE_USER_PUBLIC_KEY");
      }
      this.dropdownVisible = false;
      this.hasConnected = false;
      location.reload();
    },
    copyAction(content) {
      var that = this;
      this.$copyText(content).then(
        function (e) {
          that.$notify({
            title: "Tip",
            message: "Copy Success",
            type: "success",
            duration: 2000,
          });
          console.log(e);
        },
        function (e) {
          console.log(e);
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  height: 70px;
  background-color: #2a2a2a;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0 4.5%;
  z-index: 2;
  .logo {
    display: flex;
    align-items: center;
    margin-right: 4.5%;
    cursor: pointer;
    .logo1 {
      width: 50px;
      height: 50px;
      margin-right: 5px;
    }
    .logo2 {
      // widows: 91px;
      height: 50px;
    }
    span {
      font-size: 20px;
      color: #fff;
      padding-right: 74px;
    }
  }
  .navs {
    flex: 1;
    font-size: 16px;
    color: #fff;
    a {
      font-weight: 500;
      padding-right: 50px;
      cursor: pointer;
      &:hover {
        color: #ff8f00;
      }
      &:last-of-type {
        padding-right: 0;
      }
    }
    .active {
      color: #ff8f00;
      font-weight: bold;
    }
  }

  .bosPrice {
    padding: 0 20px;
    height: 40px;
    background: #201F1F;
    border-radius: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #FF8000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    min-width: 135px;
  }
  .connect {
    min-width: 101px;
    padding: 0 20px;
    height: 40px;
    background: #252525;
    border-radius: 20px;
    border: 1px solid rgba(97, 97, 97, 0.45);
    // line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #ff8000;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .address-dropdown {
      position: absolute;
      right: 0;
      top: 70px;
      background: #2f2f2f;
      border: 1px solid rgba(97, 97, 97, 0.44);
      border-radius: 8px;
      padding: 0 18px;

      .address {
        display: flex;
        align-items: center;
        height: 54px;
        &:hover {
          i {
            color: #ff8f00;
          }
          a {
            color: #ff8f00;
          }
        }
        i {
          color: #fff;
          margin-right: 4px;
          font-size: 14px;
        }
        a {
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
        }
        img {
          height: 14px;
          width: auto;
          margin-left: 10px;
        }
      }
      .line {
        width: 100%;
        border-bottom: 1px solid #46474a;
      }
      .disconnect {
        height: 54px;
        display: flex;
        align-items: center;
        &:hover {
          img {
            content: url("../assets/home/logout-hover@2x.png");
          }
          span {
            color: #ff8f00;
          }
        }
        img {
          margin-top: -1px;
          height: 12px;
          width: auto;
          margin-right: 4px;
        }
        span {
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
  .test {
    font-weight: 500;
    color: #ff8000;
    font-size: 16px;
    margin-left: 20px;
  }
}
@media screen and (max-width: 1000px) {
  .header {
    height: 70px;
    background-color: #2a2a2a;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    padding: 0 5%;
    z-index: 999;
    .logo {
      display: flex;
      align-items: center;
      margin-right: 5%;
      img {
        widows: 60px;
        height: 30px;
      }
      span {
        font-size: 20px;
        color: #fff;
        padding-right: 20px;
      }
    }
    .navs {
      // flex: none;
      font-size: 16px;
      color: #fff;
      display: flex;
      a {
        &:last-child {
          padding-right: 0;
        }
        cursor: pointer;
        &:hover {
          color: #ff8f00;
        }
      }
      // span:nth-child(2) {
      //   padding-right: 0;
      // }
      .active {
        color: #ff8f00;
      }
    }
    .connect {
      width: 120px;
      height: 40px;
      background: #252525;
      border-radius: 20px;
      border: 1px solid rgba(97, 97, 97, 0.45);
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #ff8000;
      cursor: pointer;
      margin-left: 10px;
    }

    .test {
      margin-left: 10px;
    }
  }
  .bosPrice {
    display: none !important;
  }
}

@media screen and (max-width: 1380px) {
  .documentionBtn {
    display: none;
  }
}
@media screen and (max-width: 1230px) {
  .inbrcBtn {
    display: none;
  }
}
@media screen and (max-width: 1130px) {
  .brc100Btn {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .layerBtn {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .test {
    display: none;
  }
}

@media screen and (max-width: 560px) {
  .logo2 {
    display: none;
  }
  .homeBtn {
    display: none;
  }

  .navs {
    justify-content: flex-end !important;
    a {
      padding-right: 16px !important;

    }
  }
}
</style>
