export const isProduction =
  process.env.VUE_APP_NETWORK === "testnet" ? false : true;

export const baseMempoolUrl = isProduction
  ? "https://mempool.space"
  : "https://mempool.space/testnet";

export const baseApiUrl = isProduction
  ? "https://api.inbrc.org"
  : "https://testnetapi.inbrc.org";
// export const baseApiUrl = isProduction
//   ? "https://api.inbrc.org"
//   : "http://192.168.1.196:8830";
  
export const baseMempoolApiUrl = isProduction
  ? "https://mempoolapi.inbrc.org/api"
  : "https://mempooltestapi.inbrc.org/api";

export const otherMempoolApiUrl = isProduction
  ? "https://mempoolapi.inbrc.org/api"
  : "https://mempooltestnetapi.inbrc.org/api";

export const baseBlockstreamApiUrl = isProduction
  ? "https://bsapi.inbrc.org"
  : "https://bstestnetapi.inbrc.org";

// export const ordinalsExplorerUrl = isProduction
//   ? "https://ordinals.inbrc.org"
//   : "https://ordinalstestnet.inbrc.org";
  export const ordinalsExplorerUrl = isProduction
  ? "https://runes.inbrc.org"
  : "https://runestestnet.inbrc.org";

export const remoteMempoolApiUrl = isProduction
  ? "https://mempoolapi.inbrc.org/api/v1"
  : "https://mempooltestnetapi.inbrc.org/api/v1";

export const btcPriceUrls = [
  "https://blockchain.info/ticker?cors=true",
  "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=USD",
];


export const baseInbrcUrl = isProduction
  ? "https://inbrc.org/"
  : "https://testnet.inbrc.org/";

export const base100SwapUrl = isProduction
  ? 'https://100swap.io/'
  : 'https://testnet.100swap.io/'

export const base100LayerUrl = isProduction
  ? 'https://100layer.io/'
  : 'https://testnet.100layer.io/'

export const publicOrdinalsExplorerUrl = isProduction
  ? "https://ordinals.com"
  : "https://testnet.ordinals.com";

export const minUtxoValue = 1000;

export const UTXO_KEY = isProduction
  ? `100SWAP_MAINNET_CAN_USE_UTXO_IDS`
  : `100SWAP_TESTNET_CAN_USE_UTXO_IDS`;