<template>
  <div
    class="tokenImage"
    :class="{ char: !getImageIcon }"
    :style="{
      width: size + 'px',
      height: size + 'px',
      fontSize: size / 2 - 1 + 'px',
    }"
  >
    <img class="logo" v-if="getImageIcon" :src="getImageIcon" />
    <template v-else>{{ getCharIcon }}</template>
    <img
      class="badge"
      v-if="isShowBadge"
      src="@/assets/icons/100list.svg"
      :style="{ width: (size > 30 ? size / 4 * 3 : size) + 'px' }"
    />
  </div>
</template>

<script>
const GraphemeSplitter = require('grapheme-splitter');
var splitter = new GraphemeSplitter();
export default {
  name: 'TokenLogo',
  props: {
    size: {
      type: Number,
      default: 30,
    },
    appfield: {
      type: String,
      default: 'application'
    },
    tickerDetail: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    getImageIcon() {
      const logo =
        this.tickerDetail.logo ||
        (this.tickerDetail[this.appfield] && this.tickerDetail[this.appfield].logo);
      if (logo) {
        return logo.includes('brc100.svg')
          ? require('@/assets/token/brc100.svg')
          : logo;
      }

      return false;
    },
    getCharIcon() {
      let { ticker } = this.tickerDetail;
      if(this.appfield === 'application0') {
        ticker = this.tickerDetail.ticker0 || this.tickerDetail[this.appfield].ticker
      }
      if(this.appfield === 'application1') {
        ticker = this.tickerDetail.ticker1 || this.tickerDetail[this.appfield].ticker
      }

      return splitter.splitGraphemes(ticker)[0];
    },
    isShowBadge() {
      if (this.tickerDetail.forkedFrom) {
        return this.tickerDetail.forkedFrom === 'BRC-20';
      }
      if (
        this.tickerDetail[this.appfield] &&
        this.tickerDetail[this.appfield].forkedFrom
      ) {
        return this.tickerDetail[this.appfield].forkedFrom === 'BRC-20';
      }
      return false;
    },
  },
};
</script>

<style scoped lang="less">
.tokenImage {
  display: inline-flex;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  .logo {
    width: 100%;
    height: 100%;
  }
  &.char {
    background: #070707;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: #ffffff;
  }
  .badge {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
